import React, { useState } from 'react'
import {navigate} from '@reach/router'
import {Layout} from '../../components/layout'
import {Section, Subsection, MiddleSectionPadding} from '../../components/section';
import {langPrefix, passLanguage, useLanguage} from '../../components/language-context'
import {
  Caption,
  CTAButton,
  OptionImage,
  PaddedImage,
  Row,
  Row3Col1,
  Row3Col2,
  RowColHalf,
  RowGraphicWrapper,
  SectionSubheading,
  TextParagraph,
  ListElement,
  Ul,
} from '../../components/platform-common-components'
import {JumboImage} from '../../components/solution-customized-components'
import analyzeHeaderImage from '../../images/platform/insights/analyze-header@3x.png'
import analyzeScreenImage from '../../images/platform/insights/analyze-screen@2x.png'
import heatmapAnalysisImage from '../../images/platform/insights/heatmap-analysis@3x.png'
import liveMapImage from '../../images/platform/insights/live-map-analysis@3x.png'
import realtimeTrafficAnalysisImage from '../../images/platform/insights/realtime-traffic-analysis@3x.png'
import { FbnModal } from '../../components/fbn-modal-component';




const ExpandableImage = ({src}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <FbnModal isOpen={modalIsOpen} width="1135px" height="596px" noOverflow={true} onClose={toggleModal} hideOnMobileView={true}>
        <img src={src} width="100%" />
      </FbnModal>
      <PaddedImage style={{cursor: 'pointer'}} src={src} onClick={toggleModal} />
    </>
  );
}

const AnalyzePageBase = useLanguage(({location, lang, msg}) => {
  const goToAlerts = () => {
    navigate(langPrefix(lang, `/platform/alerts`));
  };

  return (
    <Layout location={location}>
      <JumboImage src={analyzeHeaderImage}/>
      <Section title={msg('platform-insights-main-title')} lang={lang}>
        <SectionSubheading>{msg('platform-insights-main-desc')}</SectionSubheading>
      </Section>
      <Subsection
        padding={MiddleSectionPadding}
        title={msg('platform-insights-section-1-title')}
        lang={lang}
        showLine={true}
        lineWidth={0}
      >
        <Row>
          <TextParagraph>{msg('platform-insights-section-1-text-1')}</TextParagraph>
          <TextParagraph>{msg('platform-insights-section-1-text-2')}</TextParagraph>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={analyzeScreenImage}/>
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col2>
            <Ul marginTop={'1em'}>
              <ListElement>{msg('platform-insights-section-1-bullet-1-text')}</ListElement>
              <ListElement>{msg('platform-insights-section-1-bullet-2-text')}</ListElement>
              <ListElement>{msg('platform-insights-section-1-bullet-3-text')}</ListElement>
              <ListElement>{msg('platform-insights-section-1-bullet-4-text')}</ListElement>
            </Ul>
          </Row3Col2>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-insights-section-2-title')} lang={lang} lineWidth={0}>
        <Row>
          <RowColHalf>
            <Ul>
              <ListElement>{msg('platform-insights-section-2-bullet-1')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-2')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-3')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-4')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-5')}</ListElement>
            </Ul>
          </RowColHalf>
          <RowColHalf>
            <Ul marginTop={'1em'}>
              <ListElement>{msg('platform-insights-section-2-bullet-6')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-7')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-8')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-9')}</ListElement>
              <ListElement>{msg('platform-insights-section-2-bullet-10')}</ListElement>
            </Ul>
          </RowColHalf>
        </Row>
        <Row align='flex-start'>
          <Row3Col1 align='center'>
            <ExpandableImage src={liveMapImage}/>
            <Caption>{msg('platform-insights-section-2-caption-1')}</Caption>
          </Row3Col1>
          <Row3Col1 align='center'>
            <ExpandableImage src={heatmapAnalysisImage}/>
            <Caption>{msg('platform-insights-section-2-caption-2')}</Caption>
          </Row3Col1>
          <Row3Col1 align='center'>
            <ExpandableImage src={realtimeTrafficAnalysisImage}/>
            <Caption>{msg('platform-insights-section-2-caption-3')}</Caption>
          </Row3Col1>
        </Row>
        <Row>
          <TextParagraph>
            <CTAButton onClick={goToAlerts}>{msg('platform-insights-section-2-button-text')}</CTAButton>
          </TextParagraph>
        </Row>
      </Subsection>
    </Layout>
  )
})

const AnalyzePage = passLanguage(AnalyzePageBase)

export default AnalyzePage